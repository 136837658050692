import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../../services/auth.service';
import {LoadingBarService} from '@ngx-loading-bar/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';
import {CryptDataService} from '../../../../services/crypt-data.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  user: any;
  response: any;
  constructor(public authService: AuthService, private store: CryptDataService, private modalService: NgbModal, private loadingBar: LoadingBarService) { }

  ngOnInit() {
    const user = this.store.getData('user');
    this.user = JSON.parse(user);
  }
  signOut() {
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger',
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons.fire({
      title: 'En êtes vous sûr ?',
      html: 'Vous allez être déconnecter',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3BB260',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Non',
      confirmButtonText: 'Oui'
    }).then((result) => {
      if (result.value) {
        this.loadingBar.start();
        this.authService.signOut();
      }
    });
  }
}
