import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModalDraggableModule } from 'ngb-modal-draggable';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';

// Components
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CustomizerComponent } from './components/customizer/customizer.component';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { ContentComponent } from './components/layout/content/content.component';
import { FullComponent } from './components/layout/full/full.component';
import { LoaderComponent } from './components/loader/loader.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';

// Directives
import { DisableKeyPressDirective } from './directives/disable-key-press.directive';
import { OnlyAlphabetsDirective } from './directives/only-alphabets.directive';
import { OnlyNumbersDirective } from './directives/only-numbers.directive';
import { ShowOptionsDirective } from './directives/show-options.directive';

// Services
import { LayoutService } from './services/layout.service';
import { NavService } from './services/nav.service';
import { ExcelService } from './services/excel.service';
import { FullscreenModeService } from './services/fullscreen-mode.service';
import {AuthInterceptor} from './services/auth-interceptor.service';
// Component
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AlphaNumericDirective } from './directives/only-alpha-numbers.directive';
import { NgxMaskModule } from 'ngx-mask';
import { QRCodeModule } from 'angularx-qrcode';
import { SharedDataService } from './services/shared-data.service';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { MyAccountComponent } from './components/header/elements/my-account/my-account.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
      MyAccountComponent,
    SidebarComponent,
    ContentComponent,
    BreadcrumbComponent,
    CustomizerComponent,
    FeatherIconsComponent,
    FullComponent,
    ShowOptionsDirective,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    AlphaNumericDirective,
    LoaderComponent,
    TapToTopComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDatatableModule.forRoot(
      {
        messages: {
          emptyMessage: 'Aucune donnée dans le tableau',
          totalMessage: 'total',
          selectedMessage: 'Sélectionné'
        }
      }),
    LeafletModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    DragulaModule.forRoot(),
    NgxWebstorageModule.forRoot(),
    TranslateModule,
    AngularMultiSelectModule,
    NgbModalDraggableModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    NgxMaskModule.forRoot(),
    QRCodeModule
  ],
  providers: [
    NavService,
    LayoutService,
    ExcelService,
    NgbActiveModal,
    RxFormBuilder,
    SharedDataService,
    FullscreenModeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  exports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LoaderComponent,
    BreadcrumbComponent,
    FeatherIconsComponent,
    TapToTopComponent,
    DisableKeyPressDirective,
    OnlyAlphabetsDirective,
    OnlyNumbersDirective,
    AlphaNumericDirective,
    FooterComponent,
    MyAccountComponent
  ],
})
export class SharedModule { }
