<div class="media profile-media">
    <img class="b-r-10" src="assets/img/avatar.png" width="48" alt="">
    <div class="media-body"><span>{{ user.nom }}</span>
        <!-- <p class="mb-0 font-roboto">{{ user.DROITS_ACCES }} <i class="middle fa fa-angle-down"></i></p> -->
    </div>
</div>
<ul class="profile-dropdown onhover-show-div">
    <li>
        <a (click)="signOut()">
            <app-feather-icons [icon]="'log-out'"></app-feather-icons>
            <span>Déconnexion</span>
        </a>
    </li>
</ul>