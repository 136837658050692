import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import {CryptDataService} from './crypt-data.service'
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private crypt : CryptDataService){

    }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Récupération du token d'authentification (à remplacer par votre code)

    // Ajout du token dans les entêtes de la requête
    const authReq = request.clone({
      setHeaders: {
        'Authorization' : `Bearer ${this.crypt.getData('token').replaceAll('"', "")}`
      }
    });
    // Envoi de la requête avec les nouvelles entêtes
    return next.handle(authReq);
  }

}