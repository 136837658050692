import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { EnvService } from './env.service';
import {CryptDataService} from './crypt-data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  AUTH_API: any;
  constructor(
    private http: HttpClient,
    private storage: LocalStorageService,
    private router: Router,
    private store: CryptDataService,
    private envService: EnvService
  ) {
    this.AUTH_API = this.envService.apiUrl;
  }

  login(data): Observable<any> {
    const headers = { 'Content-Type': 'application/json'}
    
    return this.http.post<any>(this.AUTH_API + 'auth', data, {
      headers : headers
    });
  }

  isLoggedIn() {
    const user = this.store.getData('user');
    return !!user;
  }


  // Sign out
  signOut() {
    window.sessionStorage.clear(); 
    window.location.href = "auth/login";
  }
}
