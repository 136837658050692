import { Injectable } from '@angular/core';
import  *  as CryptoJS from  'crypto-js';
import {LocalStorageService} from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class CryptDataService {
  key = 'Mon@rtisan';
  constructor(private storage: LocalStorageService ) { }

  public saveData(key: string, value: string) {
    this.storage.store(key, this.encrypt(value));
  }

  public getData(key: string) {
    const data = this.storage.retrieve(key) || '';
    return this.decrypt(data);
  }
  public removeData(key: string) {
    this.storage.clear(key);
  }

  private encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, this.key).toString();
  }

  private decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, this.key).toString(CryptoJS.enc.Utf8);
  }
}
