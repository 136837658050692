import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../shared/services/auth.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { EnvService } from '../../../shared/services/env.service';
import { ILogin } from './login';
import { CryptDataService } from '../../../shared/services/crypt-data.service';

@Component({
  selector: 'app-login-validation',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  public loginModel: ILogin = new ILogin();
  public response: any;
  public AUTH_API: any;
  public countryCode = '';
  public show: boolean = false;
  public hrefLogoCIV = 'assets/img/logos/logo_ci.png';
  public hrefLogoBF = 'assets/img/logos/logo_bf.png';
  /**
   * Component constructor
   *
   * @param router: Router
   * @param auth: AuthService
   * @param cdr ChangeDetection
   * @param route Route
   */
  constructor(
    private router: Router,
    private authService: AuthService,
    private title: Title,
    private cdr: ChangeDetectorRef,
    private toastrService: ToastrService,
    private loadingBar: LoadingBarService,
    private store: CryptDataService,
    private envService: EnvService,
    private route: ActivatedRoute,
  ) {
    this.AUTH_API = this.envService.apiUrl;
    this.countryCode = this.envService.countryCode;
    this.loginModel.identifiant = '';
    this.loginModel.password = '';
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.title.setTitle(
      'Connexion | Mon Artisan - Trouvez les meilleurs artisans près de chez vous'
    );
    localStorage.clear();
  }

  showPassword(){
    this.show = !this.show;
  }

  signIn() {
    this.loadingBar.start();
    // const loginData = new FormData();
    // loginData.append('numero_tel', this.loginModel.identifiant);
    // loginData.append('password', this.loginModel.password);
    const loginData = {numero_tel : this.loginModel.identifiant, password : this.loginModel.password};
    this.authService.login(JSON.stringify(loginData)).subscribe((data: {}) => {
      this.response = data;

      console.log(this.response);
      this.loadingBar.stop();
        if(this.response.data.profil=="CLIENT"){
          this.toastrService.error("Vous n'êtes pas autorisé à continuer");
          return false
        }
        this.store.saveData('user', JSON.stringify(this.response.data));
        this.store.saveData('token', JSON.stringify(this.response.token));
        this.toastrService.success(
          `Bienvenue ${this.response.data.nom}`
        );
        this.router.navigate(['/dashboard']);
    }, (err)=>{
        this.loadingBar.stop();
        this.toastrService.error("Numéro ou mot de passe incorrect");
    });
  }
}
