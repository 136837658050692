import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../pages/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
    {
        path: 'operations',
        loadChildren: () => import('../../pages/operations/operations.module').then(m => m.OperationsModule)
    },
    {
        path: 'repertoire',
        loadChildren: () => import('../../pages/repertoire/repertoire.module').then(m => m.RepertoireModule)
    },
    {
        path: 'mailing',
        loadChildren: () => import('../../pages/mailing/mailing.module').then(m => m.MailingModule)
    },
    {
        path: 'entreprise',
        loadChildren: () => import('../../pages/entreprise/entreprise.module').then(m => m.EntrepriseModule)
    },
    {
        path: 'settings',
        loadChildren: () => import('../../pages/settings/settings.module').then(m => m.SettingsModule)
    },
    {
        path: 'facture',
        loadChildren: () => import('../../pages/facture/facture.module').then(m => m.FactureModule)
    }
];
