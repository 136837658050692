<div class="container-fluid p-0">
    <div class="row">
        <div class="col-12">
            <div class="login-card">
                <div>
                    <div>
                        <a class="logo" routerLink='/'>
                            <img width="200" [src]="countryCode === 'CIV' ? hrefLogoCIV : hrefLogoBF" />
                        </a>
                    </div>
                    <div class="login-main">
                        <form class="theme-form needs-validation">
                            <h4>Authentification</h4>
                            <p>Entrez votre adresse email et votre mot de passe pour vous connecter</p>
                            <div class="form-group">
                                <label class="col-form-label"><b>Identifiant <span class="text-danger">*</span></b></label>
                                <input class="form-control" type="text" required="" name="identifiant" [(ngModel)]="loginModel.identifiant" placeholder="Ex: admin">
                            </div>
                            <div class="form-group">
                                <label class="col-form-label"><b>Mot de passe <span class="text-danger">*</span></b></label>
                                <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]"
                                       [(ngModel)]="loginModel.password" required="" placeholder="*********">
                                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                            </div>
                            <div class="form-group mb-0">
                                <button
                                        (click)="signIn()"
                                        [disabled]="!loginModel.identifiant || !loginModel.password"
                                        [ngStyle]="{'border' : 'none'}"
                                        [class.background__civ]="countryCode === 'CIV'"
                                        [class.background__bf]="countryCode === 'BF'"
                                   class="btn btn-primary d-block w-100" type="button">
                                    Connexion
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
