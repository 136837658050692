import { Injectable } from '@angular/core';
import {formatDate} from '@angular/common';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()

export class ExcelService {
    constructor() { }
    public exportAsExcelFile(json: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }
    public exportSIFAsExcelFile(lotissement: any[], ilots_lots: any[], attributaires: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(lotissement);
        const worksheet_ILOTS_LOTS: XLSX.WorkSheet = XLSX.utils.json_to_sheet(ilots_lots);
        const worksheet_ATTRIBUTAIRES: XLSX.WorkSheet = XLSX.utils.json_to_sheet(attributaires);

        const workbook: XLSX.WorkBook = { Sheets: { 
            'LOTISSEMENT': worksheet,
            'ILOTS_LOTS': worksheet_ILOTS_LOTS,
            'ATTRIBUTAIRES': worksheet_ATTRIBUTAIRES,
        }, SheetNames: [
            'LOTISSEMENT',
            'ILOTS_LOTS',
            'ATTRIBUTAIRES',
        ] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }
    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
        let dateString = formatDate(Date.now(),'yyyy-MM-dd H:m:s', 'en-US');
        FileSaver.saveAs(data, fileName + '_export_' + dateString + EXCEL_EXTENSION);
    }
}
