import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
    layoutState: any =  'Paris';
  public config = {
    settings: {
      layout: this.layoutState,
      layout_type: 'ltr',
      layout_version: 'light',
      sidebar_type: 'default-sidebar',
   },
   color: {
      primary_color: '#FF5520',
      secondary_color: '#f73164'
   }
  }

  constructor(private localStorage: LocalStorageService) {
      this.localStorage.observe('layout')
          .subscribe((value) =>  {
              console.log('new value', value);
              this.layoutState = value;
              console.log(this.layoutState);
          })
    if(this.config.settings.layout_type == 'rtl')
      document.getElementsByTagName('html')[0].setAttribute('dir', this.config.settings.layout_type);

    document.documentElement.style.setProperty('--theme-deafult', this.config.color.primary_color);
    document.documentElement.style.setProperty('--theme-secondary', this.config.color.secondary_color);
  }


}
