<div class="container-fluid">
  <div class="page-title">
    <div class="row" [ngClass]="{'my-3': !displayGuide}">
      <div class="col-8">
        <h3 style="text-transform: none;font-weight: 800;">{{title}}</h3>
      </div>
      <div class="col-4">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="'/dashboard'">
              <app-feather-icons [icon]="'home'"></app-feather-icons>
            </a>
          </li>
          <li style="text-transform: none;" class="breadcrumb-item" *ngFor="let item of items">{{item}}</li>
          <li style="text-transform: none;" class="breadcrumb-item active">{{active_item}}</li>
        </ol>
      </div>
    </div>
  </div>
</div>
