export class EnvService {

    // The values that are defined here are the default values that can
    // be overridden by env.js

    // API url
    public apiUrl = '';
    public countryCode = '';
    public fileUrl = '';

    // HeaderTitle
    public headerTitle = '';

    // Environment Dev
    public environmentDeployment = '';

    // Whether or not to enable debug mode
    public enableDebug = true;

    constructor() {}
    
}
