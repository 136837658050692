<!-- Loader starts-->
<div class="loader-wrapper" [class.loderhide]="!show" [ngStyle]="{'z-index': '10000'}">
    <div class="logo_idufci">
        <div style="display: flex;justify-content: center;">
            <img width="80" [src]="countryCode === 'CIV' ? hrefLogoCIV : hrefLogoBF" alt="Logo MonArtisan">
        </div>
        <h4 class="text-center"
            [class.color__civ]="countryCode === 'CIV'"
            [class.color__bf]="countryCode === 'BF'" style="font-weight:800;">
            <b>SI-MONARTISAN</b>
        </h4>
    </div>
    <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
<!-- Loader ends-->