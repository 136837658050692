import { Component, OnInit, OnDestroy } from '@angular/core';
import {EnvService} from '../../services/env.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  public show = true;
  public countryCode = '';
  public hrefLogoCIV = 'assets/img/favicons/favicon_ci.png';
  public hrefLogoBF = 'assets/img/favicons/favicon_bf.png';
  constructor(private envService: EnvService) {
    setTimeout(() => {
      this.show = false;
    }, 3000);
  }

  ngOnInit() {
    this.countryCode = this.envService.countryCode;
  }

  ngOnDestroy() { }

}
