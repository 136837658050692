<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
    <div class="header-wrapper row m-0" *ngIf="typeLayout === 'Barcelona'">
        <div class="col col-12 horizontal-wrapper pl-0" style="display: flex;justify-content: center;">
            <h3 style="font-weight:800;">
                {{ headerTitle }} 
                <span class="text-danger" *ngIf="environmentDev === 'TEST'">
                    <b> (ENV. TEST)</b>
                </span>
            </h3>
        </div>
    </div>
    <div class="header-wrapper row m-0" [hidden]="typeLayout === 'Barcelona'">
        <div class="header-logo-wrapper">
            <div class="logo-wrapper">
                <a routerLink='/dashboard'>
                    <div style="position: absolute;top:35%;left:5%;">
                        <h3><b>SI-MONARTISAN.</b></h3>
                    </div>
                </a>
            </div>
            <div class="toggle-sidebar" (click)="sidebarToggle()">
                <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
            </div>
        </div>
        <div class="col col-8 horizontal-wrapper pl-0">
            <h3 class="ml-60">
                {{ headerTitle }} 
                <span class="text-danger" *ngIf="environmentDev === 'TEST'">
                    <b> (ENV. TEST)</b>
                </span>
            </h3>
        </div>
        <div class="nav-right col-4 pull-right right-header p-0">
            <ul class="nav-menus">
                <li class="maximize">
                    <a class="text-dark" href="javascript:void(0)" (click)="toggleFullScreen()">
                        <app-feather-icons [icon]="'maximize'"></app-feather-icons>
                    </a>
                </li>
                <li class="profile-nav onhover-dropdown p-0">
                    <app-my-account></app-my-account>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- Page Header Ends -->
